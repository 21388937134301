import React from "react"
import {graphql, Link} from "gatsby"
import Image from "../modules/imageComponent";

import Layout from "../components/layout";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    return (

        <Layout className={'blog'} title={frontmatter.title}>
                <section>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'col col-12'}>
                                <h1>{frontmatter.title}</h1>
                            </div>
                            <div className={'col col-12'}>
                                <div className={'menu'}>
                                    <div className={'date'}>{frontmatter.date}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'main-image'}>
                        <Image alt={frontmatter.title} filename={frontmatter.image} />
                    </div>
                    <div className={'container'} dangerouslySetInnerHTML={{ __html: html }}/>

                </section>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        image
      }
    }
  }
`
